import dynamic from 'next/dynamic';
import { Component, PropsWithChildren } from 'react';
import { redirectOnError } from 'utils/errorsHandler';
import { hasIgnorableError } from 'utils/ignorable-errors';
import { captureException } from 'utils/raven';

const Errors = dynamic(() => import('components/Errors/Errors'), { ssr: false });

interface ErrorBoundaryState {
  hasError: boolean;
  hasIgnorableError?: boolean;
}

interface Props {
  shouldNotRedirectOnError?: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren<Props>> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, hasIgnorableError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown) {
    const isIgnorable = hasIgnorableError(error);

    this.setState({ hasIgnorableError: isIgnorable });

    if (isIgnorable) {
      return;
    }

    captureException(error, {
      tags: {
        // Help to filter the errors from Lotus App, not from other external script
        from: 'ErrorBoundary',
      },
    });

    if (!this.props.shouldNotRedirectOnError) {
      redirectOnError();
    }
  }

  render() {
    const state = this.state as ErrorBoundaryState;

    if (!state.hasError) {
      return this.props.children;
    }

    if (state.hasIgnorableError) {
      return <Errors />;
    }

    return null;
  }
}

export default ErrorBoundary;
